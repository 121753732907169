import { Card, Button, Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import { axiosAT } from '../../interceptors/axiosInter'

const ActivitiesTab = () => {
  const navigate = useNavigate();
  const [subscriptionInfo, setSubscriptionInfo] = useState('');


  useEffect(() => {
    // redirect to login page if not logged
    if (localStorage.getItem('access_token') === null) {
      navigate('/login')
      return
    }
  }, []);


  useEffect(() => {
    const url = process.env.REACT_APP_BACKEND_URL + `/api/v1/subscription_status`;
    axiosAT.get(url)
      .then(response => {
        // setData(response.data);
        if (response.data.success) {
          let sub = response.data.subscription;
          if (sub) {
            if (sub.is_test) {
              setSubscriptionInfo('Nemáš aktivovaný žiadny predplatený balík spotreby. Momentálne využívaš len testovaciu verziu, ktorá nie je vhodná na prácu s citlivými dátami.');
            }
          } else {
            setSubscriptionInfo('Nemáš aktivovaný žiadny predplatený balík spotreby. Ak si ho neaktivuješ, tak nebudeš môcť využívať túto funkciu.');
          }
        }
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
      });
  }, []);


  const MyCard = ({ header, body, activity_type }) => (
    <Card style={{ width: '18rem', height: '250px', display: 'flex', flexDirection: 'column' }}>
      <Card.Body style={{ flex: '1 0 auto' }}>
        <Card.Title><b>{header}</b></Card.Title>
        <Card.Text>
          {body}
        </Card.Text>
      </Card.Body>
      <Card.Footer style={{ flexShrink: 0 }}>
        <Link to="/vytvor-aktivitu"
          state={{ header, body, activity_type }}
        >
          <Button variant="primary">Vybrať</Button>
        </Link>
      </Card.Footer>
    </Card>
  );

  return (<>
    <Container>
    {subscriptionInfo && (
        <div className="alert alert-warning" role="alert">
          {subscriptionInfo}
        </div>
      )}
      <h2 className='mb-4'>Vitaj v aplikácii na sumarizáciu audio súboru. Vyber jednu z aktivít nižšie, ktorú chceš vykonať.</h2>
      <Row className='mt-4 d-flex'>
        <Col xs="auto" className='mb-2'>
          <MyCard header='Zápisnica z domovej schôdze' body='Vygeneruj zápisnicu z domovej schôdze podľa nahrávky z diskusie alebo z poznámok od moderátora alebo správcu.' activity_type='at_schodza' />
        </Col>

        <Col xs="auto" className='mb-2'>
          <MyCard header='Prepis nahrávky' body='Doslovný prepis nahrávky s označením jednotlivých osôb a rečníkov.' activity_type='at_prepis' />
        </Col>

        <Col xs="auto" className='mb-2'>
          <MyCard header='Vlastná definícia' body='Vygeneruj text podľa nahrávky podľa vlastných inštrukcií.' activity_type='at_general' />
        </Col>
      </Row>
    </Container>
  </>
  );
};

export default ActivitiesTab;
